<template>
    <div class="animate__animated animate__fadeIn">
        <v-tabs :vertical="$root.windowWidth >= 800"
                :color="$variables.v.theme.secondary">
            <vertical-tabs v-for="navpoint in navigation" :key="navpoint.id" :navpoint="navpoint"></vertical-tabs>

            <v-tab-item>
                <change-username></change-username>
            </v-tab-item>

            <v-tab-item>
                <change-email></change-email>
            </v-tab-item>

            <v-tab-item>
                <change-password></change-password>
            </v-tab-item>

        </v-tabs>
    </div>
</template>

<script>
    import c1_change_username from "../components/sub-account/c1-change-username";
    import c2_change_email from "../components/sub-account/c2-change-email";
    import c3_change_password from "../components/sub-account/c3-change-password";


    export default {
        components: {
            "change-username": c1_change_username,
            "change-email"   : c2_change_email,
            "change-password": c3_change_password
        },
        data() {
            return {
                navigation: [
                    {id: 1, icon: "mdi-smart-card-outline", text: "account.nav.change-username"},
                    {id: 2, icon: "mdi-email", text: "account.nav.change-email"},
                    {id: 3, icon: "mdi-key-variant", text: "account.nav.change-password"}
                ],
            }
        },
        beforeRouteEnter(to, from, next) { // Prevent not signed in user from access this site
            next(vm => {
                if (vm.$user.user.auth === false)
                    next({path: vm.$variables.v.navigation.page404})
            })
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-account')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>
