<template>
    <v-card flat>
        <dialog-confirm ref="confirm"></dialog-confirm>
        <v-card-text>
            <v-card-title>{{$t("account.nav.change-password")}}</v-card-title>
            <v-card-text>
                <v-form v-model="validPassword"
                        ref="r_form_new_password">
                    <div>
                        <v-text-field v-model="old_password"
                                      :append-icon="show_pw_old ? 'mdi-eye' : 'mdi-eye-off'"
                                      :type="show_pw_old ? 'text' : 'password'"
                                      :rules="[rules.required]"
                                      :label="$t('account.old-password')"
                                      :color="$variables.v.theme.secondary"
                                      :hint="$t('login-register-account.password-min-length')"
                                      maxlength="100"
                                      counter
                                      class="default-textfield-width"
                                      @click:append="show_pw_old = !show_pw_old"></v-text-field>
                    </div>

                    <div>
                        <v-text-field v-model="new_password"
                                      :append-icon="show_pw_new1 ? 'mdi-eye' : 'mdi-eye-off'"
                                      :type="show_pw_new1 ? 'text' : 'password'"
                                      :rules="[rules.required, rules.min8, rules.max100, c_password_is_new, c_password_not_uername, c_password_not_email]"
                                      :label="$t('account.new-password')"
                                      :color="$variables.v.theme.secondary"
                                      :hint="$t('login-register-account.password-min-length')"
                                      maxlength="100"
                                      counter
                                      class="default-textfield-width"
                                      @click:append="show_pw_new1 = !show_pw_new1"></v-text-field>
                    </div>


                    <div>
                        <v-text-field v-model="new_passwordrepeat"
                                      :append-icon="show_pw_new2 ? 'mdi-eye' : 'mdi-eye-off'"
                                      :type="show_pw_new2 ? 'text' : 'password'"
                                      :rules="[rules.required, rules.min8, c_password_match]"
                                      :label="$t('account.new-password-repeat')"
                                      :color="$variables.v.theme.secondary"
                                      maxlength="100"
                                      counter
                                      class="default-textfield-width"
                                      @focusout="$refs.r_form_new_password.validate()"
                                      @click:append="show_pw_new2 = !show_pw_new2"></v-text-field>
                    </div>

                    <v-btn :disabled="!validPassword"
                           depressed
                           :color="$variables.v.theme.primary"
                           @click="change_password">
                        {{$t("account.change-password-btn")}}
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
    import sha256 from "sha256"


    export default {
        data() {
            return {
                validPassword     : false,
                old_password      : "",
                new_password      : "",
                new_passwordrepeat: "",
                show_pw_old       : false, // Eye Icon change
                show_pw_new1      : false,
                show_pw_new2      : false,


                rules: {
                    required: v => (!!v) || this.$t("rules.required"),
                    min8    : v => (v !== undefined && v.length >= 8) || this.$t("rules.min-8-chars"),
                    max100  : v => (v !== undefined && v.length <= 100) || this.$t("rules.max-100-chars"),
                }
            }
        },
        computed  : {
            c_password_is_new() {
                return () => this.old_password !== this.new_password || this.$t("rules.new-password-is-old");
            },
            c_password_not_uername() {
                return () => this.new_password !== this.$user.user.username || this.$t("rules.password-is-username");
            },
            c_password_not_email() {
                return () => this.new_password !== this.$user.user.email || this.$t("rules.password-is-email");
            },
            c_password_match() {
                return () => this.new_password === this.new_passwordrepeat || this.$t("rules.password-must-match");
            },
        },
        methods   : {
            async change_password() {
                if (this.$refs.r_form_new_password.validate()) {
                    if (await this.$refs.confirm.open(this.$t("account.dialog-confirm.password.title"), this.$t("account.dialog-confirm.password.are-you-sure"))) {
                        let response = await this.func.ax("/php/update_user.php", {
                            func        : "update_password",
                            userid      : this.$user.user.id,
                            token       : this.$user.user.token,
                            old_password: sha256.x2(this.old_password),
                            new_password: sha256.x2(this.new_password),
                        });
                        await this.$root.check_response(response, () => {
                            this.eventbus.$emit("SB", "account.msg.success-update-password", "success", 5);
                            this.old_password       = "";
                            this.new_password       = "";
                            this.new_passwordrepeat = "";
                        }, "account.msg.error-update-password");
                    }
                }
            },
        },
        directives: {
            "sha256": sha256
        },
    }
</script>