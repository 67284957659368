<template>
    <v-card flat>
        <dialog-confirm ref="confirm"></dialog-confirm>
        <v-card-text>
            <v-card-title>{{$t("account.nav.change-username")}}</v-card-title>
            <v-card-text>
                <div class="mb-5">{{$t("account.change-username-info")}}</div>

                <div class="mb-5">{{parseInt(days_since_namechange)}} {{$t("account.change-username-info-since")}}</div>

                <v-form v-model="valid_username" ref="r_form_new_username">
                    <v-text-field v-model="new_username"
                                  :rules="[rules.required, rules.min3, rules.max30, rules.no_white_spaces, c_does_usernamename_exist, c_username_not_mail, c_username_not_allowed]"
                                  :label="$t('login-register-account.username')"
                                  :color="$variables.v.theme.secondary"
                                  maxlength="30"
                                  class="default-textfield-width"
                                  :disabled="!c_username_changeable"
                                  @keyup.enter="check_username"></v-text-field>

                    <v-text-field class="d-none"></v-text-field>

                    <div class="mt-5">
                        <v-btn :disabled="!valid_username || !c_username_changeable || c_similar_username"
                               depressed
                               :color="$variables.v.theme.primary"
                               :key="unlock_change_username"
                               @click.once="change_username">
                            {{$t("account.change-username-btn")}}
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                valid_username          : false,
                new_username            : "",
                last_username_change    : "",
                days_since_namechange   : 0,
                days_for_next_namechange: 2,
                usernames               : [],
                unlock_change_username  : 0,
                rules                   : {
                    required       : v => (!!v) || this.$t("rules.required"),
                    min3           : v => (v !== undefined && v.length >= 3) || this.$t("rules.min-3-chars"),
                    max30          : v => (v !== undefined && v.length <= 30) || this.$t("rules.max-30-chars"),
                    no_white_spaces: v => (v !== undefined && !(v.indexOf(" ") >= 0)) || this.$t("rules.no-spaces-allowed"),
                }
            }
        },
        computed: {
            c_similar_username() {
                return this.new_username === this.$user.user.username;
            },
            c_username_changeable() {
                return this.days_since_namechange >= this.$variables.v.username_change_delay_in_days;
            },
            c_does_usernamename_exist() {
                return () => this.new_username && !this.usernames.includes(this.new_username.toLowerCase()) || this.$t("rules.username-exist");
            },
            c_username_not_mail() {
                return () => this.new_username !== this.$user.user.email || this.$t("rules.username-is-mail");
            },
            c_username_not_allowed() {
                let allowed = true;
                this.$variables.v.forbidden_names.forEach(name => {
                    if (this.new_username.toLowerCase().includes(name)) {
                        allowed = false;
                    }
                });
                return () => allowed || this.$t("rules.username-not-allowed");
            },
        },
        created() {
            this.new_username = this.$user.user.username;
            this.check_days_since_namechange();
        },
        methods : {
            check_days_since_namechange() {
                this.last_username_change  = Date.parse(this.$user.user.last_username_change);
                this.days_since_namechange = (new Date() - this.last_username_change) / 1000 / 60 / 60 / 24;
            },
            async check_username() {
                if (this.$user.user.username !== this.new_username)
                    if (!([this.new_username].indexOf(this.itemtypeid) >= 0)) {
                        let response = await this.$root.get_username(this.new_username);
                        if (response)
                            this.usernames.push(response.username.toLowerCase()); // Add the username to a list with used Names
                    }
                this.$refs.r_form_new_username.validate();
            },
            async change_username() {
                await this.check_username();
                if (this.$refs.r_form_new_username.validate()) {
                    let response = await this.func.ax("/php/update_user.php", {
                        func    : "update_username",
                        userid  : this.$user.user.id,
                        token   : this.$user.user.token,
                        username: this.new_username.trim(),
                    });
                    await this.$root.check_response(response, async () => {
                        await this.$user.init();
                        this.check_days_since_namechange();
                        this.eventbus.$emit("SB", "account.msg.success-update-username", "success", 5);
                    }, "account.msg.error-update-username");

                }
                this.unlock_change_username++;
            }
        },
    }
</script>