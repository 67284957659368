<template>
    <v-card flat>
        <dialog-confirm ref="confirm"></dialog-confirm>
        <v-card-text>
            <v-card-title>{{$t("account.nav.change-email")}}</v-card-title>
            <v-card-text>
                <v-form v-model="validEmail" ref="r_form_new_email">
                    <div>
                        <v-text-field v-model="new_email"
                                      :rules="[rules.required, rules.no_white_spaces, rules.valid_email, rules.max150, c_email_not_username, c_sameEmail]"
                                      :label="$t('login-register-account.email')"
                                      :color="$variables.v.theme.secondary"
                                      class="default-textfield-width"></v-text-field>
                    </div>
                    <div>
                        <v-text-field v-model="new_emailrepeat"
                                      :rules="[rules.required, c_email_match]"
                                      :label="$t('login-register-account.email-confirm')"
                                      :color="$variables.v.theme.secondary"
                                      class="default-textfield-width"></v-text-field>
                    </div>

                    <v-btn :disabled="!validEmail"
                           depressed
                           :color="$variables.v.theme.primary"
                           @click="change_email">
                        {{$t("account.change-email-btn")}}
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                validEmail     : false,
                new_email      : "",
                new_emailrepeat: "",
                rules          : {
                    required       : v => (!!v) || this.$t("rules.required"),
                    no_white_spaces: v => (v !== undefined && !(v.indexOf(" ") >= 0)) || this.$t("rules.no-spaces-allowed"),
                    valid_email    : v => (v !== undefined && /.+@.+\..+/.test(v)) || this.$t("rules.email-must-valid"),
                    max150         : v => (v !== undefined && v.length <= 150) || this.$t("rules.max-150-chars"),
                }
            }
        },
        computed: {
            c_email_not_username() {
                return () => this.new_email !== this.$user.user.username || this.$t("rules.mail-is-username");
            },
            c_sameEmail() {
                return () => this.new_email.trim() !== this.$user.user.email.trim() || this.$t("rules.same-email");
            },
            c_email_match() {
                return () => this.new_email === this.new_emailrepeat || this.$t("rules.email-must-match");
            },
        },
        methods : {
            async change_email() {
                if (this.$refs.r_form_new_email.validate()) {
                    if (await this.$refs.confirm.open(this.$t("account.dialog-confirm.email.title"), this.$t("account.dialog-confirm.email.are-you-sure"))) {
                        let response = await this.func.ax("/php/update_user.php", {
                            func    : "update_email",
                            language: this.$lang,
                            userid  : this.$user.user.id,
                            token   : this.$user.user.token,
                            email   : this.new_email.trim(),
                        });
                        await this.$root.check_response(response, () => {
                            this.new_email       = "";
                            this.new_emailrepeat = "";
                            this.eventbus.$emit("SB", "account.msg.success-update-email", "success", 10);
                        }, "account.msg.error-update-email");
                    }
                }
            },
        },
    }
</script>